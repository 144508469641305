
<div class="card-body">
    <div class="traveller-details" >
        <div class="header-box">
            <div class="main-qstn">{{ data.mainTitle }}</div>
            <div class="sub-qstn">{{ data.subTitle }}</div>
        </div>
        <div class="btn-box">   
            <button class="continue-btn" (click)="onContinue()">Yes</button>
            <button class="cancel-btn" (click)="onCancel()">No</button>
        </div>
    </div>
  </div>
  
  
  