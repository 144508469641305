import { Injectable } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { BehaviorSubject,Observable } from "rxjs";
import { ToastrManager } from 'ng6-toastr-notifications';

import { Router } from '@angular/router';
@Injectable({
    providedIn: "root",
})
export class HelperService {
    private auth = new BehaviorSubject(false);

    private userdetails = new BehaviorSubject<any>(null);
    private role = new BehaviorSubject<any>(null);
    private _data: BehaviorSubject<any> = new BehaviorSubject<any>(null);

    constructor( public toastr: ToastrManager,private snackBar: MatSnackBar, private router : Router) {}

    setAuth(status: boolean = false) {
        this.auth.next(status);
    }
    setMenu(data: any) {
        this.role.next(data);
    }
      
    getMenu(): Observable<any>  {
    return this.role.asObservable();
    }
    public setData(data: any){
      this._data.next(data);
  }

  public getData(): Observable<any> {
      return this._data.asObservable();
  }
    getAuth() {
        return this.auth.asObservable();
    }
    setUserDetails(data: any) {
        this.userdetails.next(data);
      }
      getUserDetails(): Observable<any>  {
        return this.userdetails.asObservable();
      }
    showToast(msg: string, action: string = "Okay", time: number = 3) {
        this.snackBar.open(msg || "Something went wrong", action, {
            duration: time * 1000,
        });
    }

    showToastNew(type: number = 4, message: string = 'Bad Request') {
        switch (type) {
          case 1: this.toastr.successToastr(message); break;
          case 2: this.toastr.infoToastr(message); break;
          case 3: this.toastr.warningToastr(message); break;
          case 4: this.toastr.errorToastr(message); break;
        }
      }
    
      goToPage(path: any, data = {}) {
        this.router.navigate(['/'+path], data);
        document.body.scrollTop = document.documentElement.scrollTop = 0;
      }
}
