import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";

import { Apollo, gql } from "apollo-angular";

import { FuseConfigService } from "@fuse/services/config.service";
import { fuseAnimations } from "@fuse/animations";

import { setLocalStorageItem } from "app/helpers/utils";
import { HelperService } from "app/services/helper.service";

import { Query, User } from '../../types';
import { map } from "rxjs/operators";
interface userSignIn {
    data: {
        userSignIn: {
            token: string;
            userDetails:User;
        };
    };
}

@Component({
    selector: "login",
    templateUrl: "./login.component.html",
    styleUrls: ["./login.component.scss"],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations,
})
export class LoginComponent implements OnInit {
    loginForm: FormGroup;
    loader = false;
    loginMessage: any;
    roles: any;
    hide=true;

    constructor(
        private _fuseConfigService: FuseConfigService,
        private _formBuilder: FormBuilder,
        private _router: Router,
        private _helper: HelperService,
        private _apollo: Apollo
    ) {
        // Configure the layout
        this._fuseConfigService.config = {
            layout: {
                navbar: { hidden: true },
                toolbar: { hidden: true },
                footer: { hidden: true },
                sidepanel: { hidden: true },
            },
        };
    }

    ngOnInit(): void {
        this.loginForm = this._formBuilder.group({
            emailId: ["", [Validators.required,Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"),Validators.email]],
            password: ["", Validators.required],
        });
    }

    loginUser(): void {
        localStorage.clear();

        if (this.loginForm.valid) {
            this.loader = true;

            const { emailId, password } = this.loginForm.value;

            this._apollo
                .mutate({
                    mutation: gql`
                        mutation($emailId: String!, $password: String!) {
                            userSignIn(emailId: $emailId, password: $password) {
                                token
                                userDetails{
                                    userTypeId
                                    displayName
                                    emailId
                                    id
                                }
                            }
                        }
                    `,
                    variables: {
                        emailId: emailId,
                        password: password,
                    },
                })
                .subscribe(
                    ({ data }: userSignIn) => {
                        const token = data.userSignIn.token;
                        const userType = data.userSignIn.userDetails.userTypeId;
                        setLocalStorageItem('AUTH_TOKEN', token);
                        setLocalStorageItem('DETAILS', JSON.stringify(data.userSignIn.userDetails));
                        this._helper.setUserDetails(data.userSignIn.userDetails);
                        this._apollo.watchQuery<Query>({
                            query: gql`
                                query getPermissionByUsertype($usertypeId:Int!){
                                    getPermissionByUsertype(usertypeId: $usertypeId){
                                        moduleId
                                        id
                                        userTypeId
                                        module{
                                            id
                                            moduleName
                                            display
                                            priority
                                            isMenu
                                            url
                                            icon
                                            hasOwnLink
                                            status
                                            menuId
                                            menu{
                                                id
                                                menuName
                                                status
                                            }
                                        }
                                    }
                                }`,
                                variables: {usertypeId: userType},
                                fetchPolicy:'network-only'
                          }).valueChanges.pipe(map(result => result.data.getPermissionByUsertype)).subscribe((result) => {
                            if(result){
                                this.roles = result;
                                let roleArr = new Array();
                                let menuArr = new Array();
                                menuArr.push({id: "Home",
                                title: "Home",
                                type: "group",
                                children: [{
                                        id: "dashboard",
                                        title: "Dashboard",
                                        translate: "NAV.SAMPLE.TITLE",
                                        type: "item",
                                        icon: "dashboard",
                                        url: "/dashboard",
                                    }]
                                })
                                this.roles.forEach(ele => {
                                    let type = (ele.module.isMenu == 1)?'collapsable':'group';
                                    let ind = menuArr.findIndex((item)=> ((item.id) == ((ele.module.isMenu == 1)?(ele.module.menu.menuName):'Home') && item.type == type));
                                    
                                    if(ind == -1   && ele.module.url && ele.module.icon) {
                                        let pushedEle = new Array({
                                            id: ele.module.display,
                                            title: ele.module.display,
                                            type: 'item',
                                            icon: ele.module.icon,
                                            url: ele.module.url,
                                            exactMatch: true,
                                        })
                                        let collData = {id: (ele.module.isMenu == 1)?ele.module.menu.menuName:'Home',title: (ele.module.isMenu == 1)?ele.module.menu.menuName:'Home',icon: ele.module.icon,type: type,children: pushedEle}
                                        menuArr.push(collData)
                                    }else if(ind !== -1   && ele.module.url && ele.module.icon){
                                        menuArr[ind].children.push({id: ((ele.module.isMenu == 1)?(ele.module.display):(ele.module.menu.menuName)),title: ((ele.module.isMenu == 1)?(ele.module.display):(ele.module.menu.menuName)),type: 'item',icon: ele.module.icon,url: ele.module.url,exactMatch: true})
                                    }
                                    roleArr.push(parseInt(ele.module.id));
                                });
                                
                                setLocalStorageItem('ROLES', roleArr);
                                setLocalStorageItem('side-menu', JSON.stringify(menuArr));

                                this._helper.setMenu(menuArr);
                                this._helper.setAuth(true);
                                this._router.navigateByUrl('/dashboard');
                            }
                        });
                        // this._helper.setAuth(true);
                        // this._router.navigateByUrl('/dashboard');
                    },

                    (err) => {
                        this.loader = false;
                        this.loginMessage = 'Invalid email id or password!';
                        setTimeout(() => {
                            this.loginMessage=null;
                        },3000);
                    }
                );
        }
    }
    toggleHide(){
        this.hide = !this.hide;
    }
}
