import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable({
    providedIn: "root",
})
export class UserService {
    private userData = new BehaviorSubject(null);

    constructor() {}

    setData(data: any) {
        this.userData.next(data);
    }

    getData() {
        return this.userData.asObservable();
    }
}
