import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { setLocalStorageItem } from 'app/helpers/utils';
import { HelperService } from 'app/services/helper.service';
import { Apollo, gql, QueryRef } from "apollo-angular";
import { Subscription } from 'rxjs';
import { Query} from '../../types';
import { getLocalStorageItem } from "app/helpers/utils";
interface updateProfileResponse {
  data: {
    updateUser: {
      true : any
    };
  };
}
interface changeUserPassword {
  data: {
    changeUserPassword: {
      true : any
    };
  };
}
@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
})
export class ProfileComponent implements OnInit {
  userData:any;
  profileForm: FormGroup;
  passwordChange;
  getUserQuery:QueryRef<any>;
  private querySubscription: Subscription;
  userDetails:any = JSON.parse(getLocalStorageItem('DETAILS'));
  constructor(private _helper: HelperService,private _form: FormBuilder,
    private _apollo: Apollo) {
    }

  ngOnInit(): void {
    this.setProfileForm();
    this.passwordChange = new FormGroup({
      currentpassword: new FormControl('', Validators.required),
      newpassword: new FormControl('', [Validators.required,Validators.minLength(8)]),
      confirmpassword: new FormControl('',[Validators.required]),
    });

    this.getProfileData();
  }

  setProfileForm(){
    this.profileForm = new FormGroup({
      name: new FormControl((this.userData && this.userData.displayName)?this.userData.displayName:'',[Validators.required]),
      emailId: new FormControl((this.userData && this.userData.emailId)?this.userData.emailId:'',[Validators.required]),
      userType: new FormControl((this.userData && this.userData.userType && this.userData.userType.type)?this.userData.userType.type:'',[Validators.required]),
    });
  }


  getProfileData() {
    this.getUserQuery = this._apollo.watchQuery<Query>({
      query: gql`
          query oneUser($id:ID!){
            oneUser(id:$id){
              id
              displayName
              emailId
              userType{
                type
              }
              status
            }
          }`,variables:{id:this.userDetails.id}
    });
    this.querySubscription = this.getUserQuery.valueChanges.subscribe(({data}) => {
      this.userData = data.oneUser;
      setLocalStorageItem('DETAILS', JSON.stringify(this.userData));
      this._helper.setUserDetails(this.userData);
      this.setProfileForm();
    });

  }

  profileUpdate() {
    if(this.profileForm.invalid){
      return;
    }
       this._apollo.mutate({
        mutation: gql`
          mutation updateUser($id: Int,$displayName: String) {
            updateUser(id: $id, displayName: $displayName)
          }
        `,
        variables: { id: parseInt(this.userDetails.id),displayName:this.profileForm.value.name}
      }).subscribe(({data} : updateProfileResponse) => {
        
          if(data.updateUser[0] == true){
            this._helper.showToastNew(1,'Profile Updated Successfully');
            this.getUserQuery.refetch();
          } else{
            this._helper.showToastNew(4,'Something Went Wrong..Try Again');
          }
        },(err) => {
          this._helper.showToastNew(4,err.message);
        });
  }

  async changePassword() {
    if(this.passwordChange.invalid){
      return;
    }
    if(this.passwordChange.value.newpassword !== this.passwordChange.value.confirmpassword){
      this._helper.showToastNew(4,'New Password And Confirm Password Should be same');
      return;
    }

    this._apollo.mutate({
      mutation: gql`
        mutation changeUserPassword($id: ID!,$currentPass: String,$newPass: String) {
          changeUserPassword(id: $id, currentPass: $currentPass, newPass:$newPass )
        }
      `,
      variables: { id: parseInt(this.userDetails.id),currentPass:this.passwordChange.value.currentpassword,newPass:this.passwordChange.value.newpassword}
    }).subscribe(({data} : changeUserPassword) => {
      if(data.changeUserPassword[0] == true){
        this._helper.showToastNew(1,'Password Updated Successfully');
        this.passwordChange.reset();
        for (let control in this.passwordChange.controls) {
          this.passwordChange.controls[control].setErrors(null);
        }
      } else{
        this._helper.showToastNew(4,'Something Went Wrong..Try Again');
      }
    },(err) => {
      this._helper.showToastNew(4,err.message);
    });
  }
}
