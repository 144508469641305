import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { Apollo, gql } from "apollo-angular";
import { Router } from "@angular/router";

import { FuseConfigService } from '@fuse/services/config.service';
import { fuseAnimations } from '@fuse/animations';
import { HelperService } from 'app/services/helper.service';

interface ForgotPassResponse {
    data: {
        userForgotPassword: boolean
    };
}

@Component({
  selector: 'forgot-password',
  templateUrl: './forgotpsw.component.html',
  styleUrls: ['./forgotpsw.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations   : fuseAnimations
})
export class ForgotpswComponent implements OnInit {

  forgotPasswordForm: FormGroup;
  loader: any;
  successMessage: any;
  successStatus: any;

    /**
     * Constructor
     *
     * @param {FuseConfigService} _fuseConfigService
     * @param {FormBuilder} _formBuilder
     */
    constructor(
        private _fuseConfigService: FuseConfigService,
        private _formBuilder: FormBuilder,
        private _apollo: Apollo,
        private _router: Router,
        private _helper: HelperService
    )
    {
        // Configure the layout
        this._fuseConfigService.config = {
            layout: {
                navbar   : {
                    hidden: true
                },
                toolbar  : {
                    hidden: true
                },
                footer   : {
                    hidden: true
                },
                sidepanel: {
                    hidden: true
                }
            }
        };
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void
    {
        this.forgotPasswordForm = this._formBuilder.group({
            emailId          : ['', Validators.compose([Validators.required , Validators.email])]
        });
    }

    changePassword(): void {
        this.loader = true;
        const passData = {
            emailId : this.forgotPasswordForm.value.emailId
        };
        this._apollo
                .mutate({
                    mutation: gql`
                        mutation($emailId: String!) {
                            userForgotPassword(emailId: $emailId)
                        }
                    `,
                    variables: {
                        emailId: passData.emailId,
                    },
                })
                .subscribe(
                    ({ data }: ForgotPassResponse) => {
                        this.loader = false;
                        this.successStatus = 1;
                        this.successMessage = "Email sent successfully";
                        this._helper.showToastNew(1,"Email sent successfully! Please login with new password!")
                        this._helper.goToPage('/')
                    },

                    (err) => {
                        this.loader = false;
                        this.successMessage = "Something went wrong! Email not sent.";
                    }
                );
    }
}
