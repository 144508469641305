
<div id="profile" class="page-layout simple tabbed" >
  <!-- <div class="header accent" fxLayout="row" fxLayoutAlign="space-between center">
      <div class="logo mb-24 mb-md-0" fxLayout="row" fxLayoutAlign="start center">
        <span class="logo-text h1"> Profile </span>
      </div>
  </div> -->
  <div class="about-content">
    <div id="about" class="p-24" fxLayout="row wrap">
      <div class="about-content" fxLayout="column" fxFlex="100" fxFlex.gt-sm="50" fxFlex.gt-md="50">
        <div class="profile-box info-box general" fxLayout="column">
          <header class="accent">
            <div class="title">Profile Information</div>
          </header>
          
          <div class="content-card">
            
            <form class="p-24 w-50-p" [formGroup]="profileForm" (ngSubmit)="profileUpdate()">
              <mat-form-field appearance="outline" floatLabel="always" class="w-100-p">
                <mat-label>Name</mat-label>
                <input matInput placeholder="Name" formControlName="name"/>
                <mat-error *ngIf="profileForm.controls['name'].hasError('required')"> Name is required </mat-error>
              </mat-form-field>
              <mat-form-field appearance="outline" floatLabel="always" class="w-100-p">
                <mat-label>Email ID</mat-label>
                <input matInput placeholder="Email ID"  class="cursorClassNone" readonly formControlName="emailId" />
              </mat-form-field>
              <mat-form-field appearance="outline" floatLabel="always" class="w-100-p">
                <mat-label>Usertype</mat-label>
                <input matInput placeholder="Usertype" readonly formControlName="userType"/>
                <mat-error *ngIf="profileForm.controls['userType'].hasError('required')">Usertype is required </mat-error>
              </mat-form-field>
              
              
              
              <br/><br/>
              <button mat-raised-button color="primary"  [disabled]="profileForm.invalid" >
                <span>Update</span>
              </button>
            </form>
          </div>
        </div>
      </div>
      
      <div class="about-sidebar" fxLayout="column" fxFlex="100" fxFlex.gt-sm="50" fxFlex.gt-md="50" >
        <div class="profile-box info-box general" fxLayout="column">
          <header class="accent">
            <div class="title">Change Password</div>
          </header>
          
          <div class="content-card">
            <form [formGroup]="passwordChange" class="p-24 w-50-p" (ngSubmit)="changePassword()">
              <mat-form-field appearance="outline" floatLabel="always" class="w-150-p">
                <mat-label>Current Password</mat-label>
                <input matInput placeholder="Current Password" type="password" formControlName="currentpassword" />
                <mat-error *ngIf="passwordChange.controls['currentpassword'].hasError('required')"> Current Password is required </mat-error>
              </mat-form-field>
              
              <mat-form-field appearance="outline" floatLabel="always" class="w-150-p">
                <mat-label>New Password</mat-label>
                <input matInput placeholder="New Password"  type="password" formControlName="newpassword" />
                <mat-error *ngIf="passwordChange.controls['newpassword'].hasError('required')"> New Password is required </mat-error>
                <mat-error *ngIf="passwordChange.controls['newpassword'].hasError('minlength')">Min Length is 8 </mat-error>
              </mat-form-field>
              
              <mat-form-field appearance="outline" floatLabel="always" class="w-150-p">
                <mat-label>Confirm Password</mat-label>
                <input matInput placeholder="Confirm Password"  type="password" formControlName="confirmpassword" />
                  <mat-error *ngIf="passwordChange.controls['confirmpassword'].hasError('required') "> Confirm Password is required </mat-error>
                  <mat-error *ngIf="passwordChange.controls['confirmpassword'].valid && passwordChange.controls['newpassword'].value != passwordChange.controls['confirmpassword'].value "> New Password and Confirm Password Should be same </mat-error>
              </mat-form-field>
              <br/><br/>
              <button mat-raised-button color="primary"  [disabled]="profileForm.invalid" >
                <span>Update</span>
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
