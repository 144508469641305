<div class="loader_common_main_wrapper" *ngIf="loader">
    <div class="loader"></div>
</div>
<div id="forgot-password" fxLayout="column">

    <div id="forgot-password-form-wrapper" fxLayout="column" fxLayoutAlign="center center">

        <div id="forgot-password-form" [@animate]="{value:'*',params:{duration:'300ms',y:'100px'}}" style="background-color: #ffffff;">

            <div class="logo">
                <img src="assets/images/logos/logo.png">
            </div>

            <div class="title">RECOVER PASSWORD</div>
            <form name="forgotPasswordForm" [formGroup]="forgotPasswordForm" novalidate>

                <mat-form-field appearance="outline">
                    <mat-label>Registered Email ID</mat-label>
                    <input matInput formControlName="emailId">
                    <mat-icon matSuffix class="secondary-text">mail</mat-icon>
                    <mat-error *ngIf="forgotPasswordForm.get('emailId').hasError('required')">
                        Email is required
                    </mat-error>
                    <mat-error *ngIf="forgotPasswordForm.get('emailId').hasError('emailId')">
                        Please Enter a valid email address
                    </mat-error>
                </mat-form-field>
                <div class="loginfailed" *ngIf="successStatus === 1">{{successMessage}}</div>
                <button mat-raised-button class="submit-button" color="accent"
                        aria-label="SEND RESET LINK" [disabled]="forgotPasswordForm.invalid" (click)="changePassword();">
                   RESET PASSWORD 
                </button>
            </form>

            <div class="login" fxLayout="row" fxLayoutAlign="center center">
                <a class="link" [routerLink]="'/login'">Go back to login</a>
            </div>

        </div>

    </div>

</div>
