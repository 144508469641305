import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { HttpClientModule } from "@angular/common/http";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { RouterModule, Routes } from "@angular/router";
import { MatMomentDateModule } from "@angular/material-moment-adapter";
import { MatButtonModule } from "@angular/material/button";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatIconModule } from "@angular/material/icon";
import { MatPaginatorModule } from '@angular/material/paginator';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import { MatMenuModule } from '@angular/material/menu';
import { TranslateModule } from "@ngx-translate/core";

import { FuseModule } from "@fuse/fuse.module";
import { FuseSharedModule } from "@fuse/shared.module";
import { FuseProgressBarModule, FuseSidebarModule, FuseThemeOptionsModule } from "@fuse/components";
import { ToastrModule } from 'ng6-toastr-notifications';
import { fuseConfig } from "app/fuse-config";

import { DenyAuthGuard, AccessAuthGuard } from "app/services/auth.guard";

import { AppComponent } from "app/app.component";
import { LoginComponent } from "app/pages/login/login.component";
import { ProfileComponent } from "app/pages/profile/profile.component";

import { LayoutModule } from "app/layout/layout.module";
import { HomeModule } from "app/pages/home/home.module";
import { DashboardComponent } from "./pages/home/dashboard/dashboard.component";
import { ForgotpswModule } from './forgotpsw/forgotpsw.module';
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { AngularEditorModule } from '@kolkov/angular-editor';

import { Apollo, APOLLO_OPTIONS } from "apollo-angular";
import { HttpLink } from "apollo-angular/http";
import { InMemoryCache } from "@apollo/client/core";
import { environment } from "environments/environment";
import { setContext } from '@apollo/client/link/context';
import {onError} from '@apollo/client/link/error';
import { CancelPopUpComponent } from './pages/components/cancel-pop-up/cancel-pop-up.component';

const error = onError(({networkError}) => {
  if (networkError) {  
    localStorage.clear();
    location.reload();
  }
});
const appRoutes: Routes = [
    {
        path: "",
        redirectTo: "login",
        pathMatch: "full",
    },
    {
        path: "dashboard",
        component: DashboardComponent,
        canActivate: [AccessAuthGuard],
    },
    {
        path: "login",
        component: LoginComponent,
        canActivate: [DenyAuthGuard],
    },
    {
        path: "profile",
        component: ProfileComponent,
        canActivate: [AccessAuthGuard],
    },
    {
        path: "agents",
        canActivate: [AccessAuthGuard],
        loadChildren: () => import("./pages/agents/agents.module").then((m) => m.AgentModule),
    },
    {
        path: "transactions",
        canActivate: [AccessAuthGuard],
        loadChildren: () => import("./pages/transactions/transactions.module").then((m) => m.TransactionsModule),
    },
    {
        path: "providers",
        canActivate: [AccessAuthGuard],
        loadChildren: () => import("./pages/providers/providers.module").then((m) => m.ProvidersModule),
    },
    {
        path: "user-types",
        canActivate: [AccessAuthGuard],
        loadChildren: () => import("./pages/user-types/user-types.module").then((m) => m.UserTypesModule),
    },
    {
        path: "users",
        canActivate: [AccessAuthGuard],
        loadChildren: () => import("./pages/users/users.module").then((m) => m.UserModule),
    },
    {
        path: "bookings",
        canActivate: [AccessAuthGuard],
        loadChildren: () => import("./pages/bookings/booking.module").then((m) => m.BookingModule),
    },
    {
        path: "refundRequests",
        canActivate: [AccessAuthGuard],
        loadChildren: () => import("./pages/refundRequets/refundRequets.module").then((m) => m.RefundRequetsModule),
    },
    {
        path: "walletRechargeRequest",
        canActivate: [AccessAuthGuard],
        loadChildren: () => import("./pages/walletRechargeRequest/walletRechargeRequest.module").then((m) => m.WalletRechargeRequest),
    },
    
    {
        path: "email-templates",
        canActivate: [AccessAuthGuard],
        loadChildren: () => import("./pages/email-templates/email-template.module").then((m) => m.EmailTemplateModule),
    },
    {
        path: "settings",
        canActivate: [AccessAuthGuard],
        loadChildren: () => import("./pages/settings/settings.module").then((m) => m.SettingsModule),
    },
    {
        path: "currency",
        canActivate: [AccessAuthGuard],
        loadChildren: () => import("./pages/currency/currency.module").then((m) => m.CurrencyModule),
    },
];

  const authLink = setContext((_, { headers }) => {
    // get the authentication token from local storage if it exists
    const token = localStorage.getItem('AUTH_TOKEN');
    
    // return the headers to the context so httpLink can read them
    return {
      headers: {
        ...headers,
        "x-token": token ? token : "",
      }
    }
  });

@NgModule({
    declarations: [AppComponent, LoginComponent,ProfileComponent, CancelPopUpComponent],
    providers: [
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        RouterModule.forRoot(appRoutes),

        TranslateModule.forRoot(),

        ToastrModule.forRoot(),
        // Material moment date module
        MatMomentDateModule,

        // Material
        MatButtonModule,
        MatProgressSpinnerModule,
        MatIconModule,
        MatSnackBarModule,
        MatFormFieldModule,
        MatInputModule,
        MatPaginatorModule,
        MatMenuModule,
        AngularEditorModule,

        // Fuse modules
        FuseModule.forRoot(fuseConfig),
        FuseProgressBarModule,
        FuseSharedModule,
        FuseSidebarModule,
        FuseThemeOptionsModule,

        // App modules
        LayoutModule,
        ForgotpswModule,
        HomeModule,
    ],
    bootstrap: [AppComponent],
})
export class AppModule {
    constructor(apollo:Apollo,httpLink:HttpLink){
        const auth: any = { uri: environment.graphqlServer };
        const flight: any = { uri:environment.graphqlServer2};
        const hotel: any = { uri:environment.graphqlServer3 };
        const ground: any = { uri:environment.graphqlServer4 };
        const insurance: any={ uri:environment.graphqlServer5};

        apollo.createDefault({
            link: error.concat(authLink.concat(httpLink.create(auth))),
            cache: new InMemoryCache()
        });

        apollo.createNamed('FLIGHT_API',{
            link:authLink.concat(httpLink.create(flight)),
            cache:new InMemoryCache()
        });

        apollo.createNamed('HOTEL_API',{
            link:authLink.concat(httpLink.create(hotel)),
            cache:new InMemoryCache()
        });

        apollo.createNamed('GROUND_API',{
            link:authLink.concat(httpLink.create(ground)),
            cache:new InMemoryCache()
        });

        apollo.createNamed('INSURANCE_API',{
            link:authLink.concat(httpLink.create(insurance)),
            cache:new InMemoryCache()
        });
    }
}
