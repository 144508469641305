import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-cancel-pop-up',
  templateUrl: './cancel-pop-up.component.html',
  styleUrls: ['./cancel-pop-up.component.scss']
})
export class CancelPopUpComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,public dialogRef: MatDialogRef<CancelPopUpComponent>) { }

  ngOnInit(): void {
  }

  onCancel(){
    this.dialogRef.close({confirmStatus:false});
  }
  onContinue(){
    this.dialogRef.close({confirmStatus:true});
  }

}
