<!-- <div class="loader_common_main_wrapper" *ngIf="loader">
    <div class="loader"></div>
</div> -->
<div style="text-align: center; width: 100%; display: flex; justify-content: center; margin-top: auto; margin-bottom: auto;" *ngIf="loader">
    <mat-spinner></mat-spinner>
  </div>

<div id="login" fxLayout="column" *ngIf="!loader">
    <div id="login-form-wrapper" fxLayout="column" fxLayoutAlign="center center">
        <div
            id="login-form"
            [@animate]="{
                value: '*',
                params: { duration: '300ms', y: '100px' }
            }"
            style="background-color: #ffffff"
        >
            <div class="logo">
                <img src="assets/images/logos/logo2.png" />
            </div>
            <div class="title">LOGIN</div>
            <form name="loginForm" [formGroup]="loginForm" novalidate>
                <mat-form-field appearance="outline">
                    <mat-label>Email Id</mat-label>
                    <input matInput formControlName="emailId" autocomplete="off" />
                    <mat-icon matSuffix class="secondary-text">mail</mat-icon>
                    <mat-error *ngIf="loginForm.get('emailId').hasError('required')">
                        Username or Email is required
                    </mat-error>
                    <mat-error *ngIf="loginForm.get('emailId').hasError('pattern') || loginForm.get('emailId').hasError('email')">
                        Username or Email Invalid
                    </mat-error>
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label>Password</mat-label>
                    <input
                        matInput
                        [type]="hide ? 'password': 'text'"
                        formControlName="password"
                        placeholder="Minimum Length 6"
                        autocomplete="off"
                    />
                    <mat-icon matSuffix (click)="toggleHide()" class ="hideEye secondary-text">{{hide ? 'visibility_off':'visibility'}}</mat-icon>
                
                    <mat-error> Password is required </mat-error>
                </mat-form-field>

                <div class="remember-forgot-password text-center" fxLayout="row" fxLayout.xs="column"
                     fxLayoutAlign="space-between center">
                    <a class="forgot-password"  [routerLink]="'/forgotpassword'">
                        Forgot Password?
                    </a>
                </div>

                <div class="loginfailed" *ngIf="loginMessage === 'Invalid email id or password!'">Invalid email id or password!</div>
                <button
                    mat-raised-button
                    color="accent"
                    class="submit-button"
                    aria-label="LOG IN"
                    [disabled]="loginForm.invalid"
                    (click)="loginUser()"
                >
                    LOGIN
                </button>
            </form>
        </div>
    </div>
</div>

