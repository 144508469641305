import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { getToken } from '../helpers/utils';

@Injectable({
  providedIn: 'root',
})
export class AccessAuthGuard implements CanActivate {
  constructor(private myRoute: Router) {}
  canActivate(): Observable<boolean> | Promise<boolean> | boolean {
    if (getToken()) return true;
    else {
      this.myRoute.navigateByUrl('/login');
      return false;
    }
  }
}

@Injectable({
  providedIn: 'root',
})
export class DenyAuthGuard implements CanActivate {
  constructor(private myRoute: Router) {}
  canActivate(): Observable<boolean> | Promise<boolean> | boolean {
    if (getToken()) {
      this.myRoute.navigateByUrl('/');
      return false;
    } else return true;
  }
}
