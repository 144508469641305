import { NgModule } from '@angular/core';
import { FuseSharedModule } from '@fuse/shared.module';
import { MatIconModule } from "@angular/material/icon";
import { DashboardComponent } from './dashboard/dashboard.component';

@NgModule({
    imports: [
        MatIconModule,
        FuseSharedModule
    ],
    exports: [],
    declarations: [DashboardComponent],
    providers: [],
})
export class HomeModule { }
