// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,
    hmr: false,
    graphqlServer: "https://api.equatortravels.com/equator-auth/graphql",
    graphqlServer2: "https://api.equatortravels.com/equator-booking/graphql", 
    graphqlServer3: "https://api.equatortravels.com/equator-hotel/graphql", 
    graphqlServer4: "https://api.equatortravels.com/equator-ground/graphql", 
    graphqlServer5: "https://api.equatortravels.com/equator-insurance/graphql", 
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
