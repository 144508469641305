import { FuseNavigation } from "@fuse/types";

export const navigation: FuseNavigation[] = [
    {
        id: "home",
        title: "Home",
        translate: "NAV.APPLICATIONS",
        type: "group",
        children: [
            {
                id: "dashboard",
                title: "Dashboard",
                translate: "NAV.SAMPLE.TITLE",
                type: "item",
                icon: "dashboard",
                url: "/dashboard",
            },
            {
                id: "transactions",
                title: "Transactions",
                translate: "NAV.SAMPLE.TITLE",
                type: "item",
                icon: "receipt",
                url: "/transactions",
            },
            {
                id: "providers",
                title: "Providers",
                translate: "NAV.SAMPLE.TITLE",
                type: "item",
                icon: "list",
                url: "/providers",
            },
            {
                id: "users",
                title: "Users",
                translate: "NAV.SAMPLE.TITLE",
                type: "item",
                icon: "supervised_user_circle",
                url: "/users",
            },
            {
                id: "usertypes",
                title: "User Types",
                translate: "NAV.SAMPLE.TITLE",
                type: "item",
                icon: "supervisor_account",
                url: "/user-types",
            },
        ],
    },
  
    {
        id: "agents",
        title: "Agents",
        translate: "NAV.SAMPLE.TITLE",
        type: "collapsable",
        icon: "account_box",
        children: [
            {
                id: "approved",
                title: "Approved",
                translate: "NAV.SAMPLE.TITLE",
                type: "item",
                icon: "account_box",
                url: "/agents/approved",
            },
            {
                id: "requested",
                title: "Requested",
                translate: "NAV.SAMPLE.TITLE",
                type: "item",
                icon: "account_box",
                url: "/agents/requested",
            },
        ],
        
    },
    {
        id: "transactions",
        title: "Transactions",
        translate: "NAV.SAMPLE.TITLE",
        type: "item",
        icon: "receipt",
        url: "/transactions",
    },
    {
        id: "providers",
        title: "Providers",
        translate: "NAV.SAMPLE.TITLE",
        type: "item",
        icon: "list",
        url: "/providers",
    },
    {
        id: "email",
        title: "Email Templates",
        translate: "NAV.SAMPLE.TITLE",
        type: "item",
        icon: "mail_outline",
        url: "/email-templates",
    },
    {
        id: "users",
        title: "Users",
        translate: "NAV.SAMPLE.TITLE",
        type: "item",
        icon: "supervised_user_circle",
        url: "/users",
    },
    {
        id: "usertypes",
        title: "User Types",
        translate: "NAV.SAMPLE.TITLE",
        type: "item",
        icon: "supervisor_account",
        url: "/user-types",
    },
  
];
